import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import ROUTES from '../../../common/helpers/routes';

export const StyledLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
`;

const AccountCreationLink = () => {
  const theme = useContext(ThemeContext);

  return (
    <StyledLink>
      <Link data-test="create_test_account_link" to={ROUTES.home} style={{ color: theme.colors.primary.regular }}>
        <FormattedMessage
          id="developer.success.page.create.account.button"
          defaultMessage="Create another test account"
        />
      </Link>
    </StyledLink>
  );
};

export default AccountCreationLink;
