import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { SvgIcon } from '@worldline/gaia';
import { Icon } from 'og-merchant-portal-react-library';

const StyledTitle = styled.h1`
  text-align: center;
  font-weight: ${props => props.theme.font.weights.medium};
  font-size: 2.8rem;
  height: 3.4rem;
  line-height: 3rem;
  margin-bottom: 1rem;
  margin-top: 0;
`;

const StyledInlineTitle = styled(StyledTitle)`
  margin-bottom: 3.2rem;
`;

const StyledInlineIcon = styled.div`
  display: inline;
  margin-right: 5px;
  span {
    transform: translateY(5px);
  }
`;

const StyledIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  path {
    fill: ${props => props.theme.colors.primary.regular};
  }
`;

const ConfirmationTitle = ({ icon, isIconInline, children }) => {
  const theme = useContext(ThemeContext);

  const renderInlineTitle = () => (
    <StyledInlineTitle data-test="confirmation_inline_title">
      <StyledInlineIcon>
        <SvgIcon
          color={theme.colors.primary.regular}
          dataTest="confirmaiton_inline_icon"
          name={icon}
          size="3.0rem"
        />
      </StyledInlineIcon>
      {children}
    </StyledInlineTitle>
  );

  const renderTitleWithSeparatedIcon = () => (
    <>
      <StyledTitle data-test="confirmation_title">{children}</StyledTitle>
      <StyledIcon data-test="confirmaiton_separate_icon">
        <Icon name={icon} size={40} color={theme.colors.primary.regular} />
      </StyledIcon>
    </>
  );

  return isIconInline ? renderInlineTitle() : renderTitleWithSeparatedIcon();
};

ConfirmationTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  isIconInline: PropTypes.bool,
  children: PropTypes.node.isRequired
};

ConfirmationTitle.defaultProps = {
  isIconInline: false
};

export default ConfirmationTitle;
