import styled from 'styled-components';
import DeviceScreenMaxWidth from '../../common/helpers/deviceScreenSizeHelper';

export const StyledContainerDiv = styled.div`
  max-width: 500px;
  margin: 50px;
  background: ${props => props.theme.colors.greyScale.white};
  border-radius: 16px;
  filter: drop-shadow(0px 3px 14px rgba(78, 71, 69, 0.3));
  padding: 40px 80px;
  font-size: 16px;

  @media screen and (max-width: ${DeviceScreenMaxWidth.mobile}) {
    padding: 48px 32px;
  }

  @media screen and (min-width: ${DeviceScreenMaxWidth.mobile}) and (max-width: ${DeviceScreenMaxWidth.tablet}) {
    padding: 48px 50px;
  }
`;

export const StyledInlineTitle = styled.p`
  margin-bottom: 5px;
  font-weight: ${props => props.theme.font.weights.semibold};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 32px;

  button {
    padding: 10px 24px;
  }
`;

export const StyledParagraph = styled.p`
  margin-bottom: 32px;
  word-wrap: break-word;
`;

export const StyledLowMarginParagraph = styled(StyledParagraph)`
  margin-bottom: 16px;
`;
