import { SpinnerLoading } from 'og-merchant-portal-react-library';
import React from 'react';
import StyledLoading from './Spinner.styled';

function Spinner() {
  return (
    <StyledLoading data-test="loading-indicator">
      <SpinnerLoading />
    </StyledLoading>
  );
}

export default Spinner;
