const httpClient = () => {
  httpClient.getSetting = name => {
    const { appSettings } = window;
    if (!appSettings) {
      throw new Error('app settings are not loaded. Please check your index file');
    }
    const setting = appSettings[name];
    if (!setting) {
      throw new Error(
        `${name} is not defined in your settings. Please check the appSettings file.`
      );
    }
    return setting;
  };

  httpClient.handleErrors = response => {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        resolve(response);
      } else {
        response.json().then(
          resp => {
            reject(resp);
          },
          () => {
            reject(new Error(response.statusText));
          }
        );
      }
    });
  };

  httpClient.get = url => {
    const baseUrl = httpClient.getSetting('apiBaseUrl');
    return fetch(baseUrl + url)
      .then(httpClient.handleErrors)
      .then(response => response.json());
  };

  httpClient.post = (url, data) => {
    const baseUrl = httpClient.getSetting('apiBaseUrl');
    const requestOptions = {
      method: 'post',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    };
    return fetch(baseUrl + url, requestOptions)
      .then(httpClient.handleErrors)
      .then(res => res.text())
      .then(text => (text.length ? JSON.parse(text) : null));
  };

  return httpClient;
};

export default httpClient();
