export const getAllFor = locale => {
  return fetch(`/assets/${locale}.json`, { cache: 'no-cache' }).then(response => response.json());
};

export const getAvailableTranslationsList = () => {
  if (!window.appSettings.supportedLanguages) {
    return ['EN'].map(locale => {
      return locale.toLowerCase();
    });
  }

  return window.appSettings.supportedLanguages.map(locale => {
    return locale.toLowerCase();
  });
};
