import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { DropDown } from 'og-merchant-portal-react-library';
import Locales from '../../helpers/Internationalization/locales';
import Control from './Control';
import I18nContext from '../../helpers/Internationalization/I18nContext';
import Option from './Option';

const LanguageSelector = () => {
  const theme = useContext(ThemeContext);
  const { availableTranslations, locale, changeLocale } = useContext(I18nContext);

  const [options, setOptions] = React.useState([]);

  const selectedLocale = { value: locale, label: locale };
  const availableLocalesAndTranslations =
    Object.keys(Locales).length !== 0 && availableTranslations.length > 1;

  const onOptionChange = option => {
    if (locale !== option.value) {
      changeLocale(option.value);
    }
  };

  React.useEffect(() => {
    setOptions(
      availableTranslations.map(language => {
        return {
          value: language,
          label: Locales[language]
        };
      })
    );
  }, [availableTranslations]);

  if (availableLocalesAndTranslations) {
    return (
      <DropDown
        name="global_menu_item_language_selector"
        value={selectedLocale}
        options={options}
        onChange={onOptionChange}
        isSearchable={false}
        data-test="global_menu_item_language_selector"
        styles={{
          menu: () => ({
            zIndex: 3
          }),
          control: () => ({
            minWidth: '2.4rem',
            '&:hover': {
              cursor: 'pointer'
            },
            '> div': {
              display: 'flex'
            },
            border: 'none'
          }),
          valueContainer: () => ({
            padding: 0,
            minWidth: '2.4rem',
            '& > div': {
              margin: 0,
              minWidth: '2.4rem',
              fontSize: theme.font.sizes.md,
              fontWeight: theme.font.weights.regular,
              color: theme.colors.greyScale.body,
              textTransform: 'uppercase'
            }
          }),
          indicatorsContainer: () => ({
            '& > div': {
              padding: 0
            }
          }),
          option: () => ({
            padding: '0.8rem 1.6rem',
            '&:hover div span': {
              color: theme.colors.primary.regular
            }
          })
        }}
        components={{ Option, Control }}
      />
    );
  }

  return null;
};

export default LanguageSelector;
