import { Button } from '@worldline/gaia';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledButtonContainer } from '../SuccessPage.style';

const ChooseIntegrationButton = () => {
  const { integrationSupportUrl } = window.appSettings;

  const openIntegrationUrl = () => {
    window.open(integrationSupportUrl, '_blank');
  };

  return (
    <StyledButtonContainer data-test="button_container">
      <Button dataTest="choose_integration_button" onClick={openIntegrationUrl}>
        <FormattedMessage
          id="developer.success.page.integration.button"
          defaultMessage="Choose your integration"
        />
      </Button>
    </StyledButtonContainer>
  );
};

export default ChooseIntegrationButton;
