import { createGlobalStyle } from 'styled-components';

function getFontUrl(props) {
  return `url("${props.theme.font.url}/${props.theme.font.family}.woff2")`;
}

const GlobalStyleComponent = createGlobalStyle`
  @font-face {
    font-family: ${props => props.theme.font.family};
    src: ${props => getFontUrl(props)} format("woff2 supports variations"), ${props =>
  getFontUrl(props)} format("woff2-variations");
    font-weight: ${props => props.theme.font.weights.light} ${props =>
  props.theme.font.weights.semibold};
    font-style: normal;
    font-display: swap;
  }

  html {
    font-size: 62.5%;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  body {
    margin: 0 auto;
    height: 100%;
    color: ${props => props.theme.colors.greyScale.body};
    background-color: ${props => props.theme.colors.greyScale.rowBackground};
    max-width: 144rem;
  }

  body * {
    font-family: ${props => `${props.theme.font.family}, ${props.theme.font.fallbacks}`};
    line-height: 1.16;
  }
`;

export default GlobalStyleComponent;
