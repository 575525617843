import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import nl from 'react-intl/locale-data/nl';
import it from 'react-intl/locale-data/it';
import de from 'react-intl/locale-data/de';
import es from 'react-intl/locale-data/es';

import I18nContext from './I18nContext';
import { getAllFor } from './translationApi';

addLocaleData([...en, ...fr, ...nl, ...it, ...de, ...es]);

export default class I18nProvider extends React.PureComponent {
  static propTypes = {
    locale: PropTypes.string.isRequired,
    translatedMessages: PropTypes.object,
    availableTranslations: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.element.isRequired
  };

  static defaultProps = {
    translatedMessages: {},
    availableTranslations: ['en']
  };

  constructor(props) {
    super(props);
    const { locale, translatedMessages, availableTranslations } = this.props;
    global.localStorage.setItem('locale', locale);
    this.state = {
      locale,
      translatedMessages,
      availableTranslations, // eslint-disable-line
      changeLocale: this.changeLocale, // eslint-disable-line
      addTranslations: this.addTranslations // eslint-disable-line
    };
  }

  changeLocale = newLocale => {
    global.localStorage.setItem('locale', newLocale);
    getAllFor(newLocale).then(messages => {
      this.setState({ translatedMessages: messages, locale: newLocale });
    });
  };

  addTranslations = newTranslations => {
    this.setState(prevState => ({
      translatedMessages: {
        ...prevState.translatedMessages,
        ...newTranslations
      }
    }));
  };

  render() {
    const { children } = this.props;
    const { locale, translatedMessages } = this.state;

    return (
      <I18nContext.Provider value={this.state}>
        <IntlProvider key={locale} locale={locale} messages={translatedMessages}>
          {children}
        </IntlProvider>
      </I18nContext.Provider>
    );
  }
}
