import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@worldline/gaia';
import { StyledButtonContainer } from '../SuccessPage.style';

const MerchantPortalButton = () => {
  const { merchantPortalUrl } = window.appSettings;

  const openMerchantPortalUrl = () => {
    window.open(merchantPortalUrl, '_blank');
  };

  return (
    <StyledButtonContainer data-test="merchant_portal_button_container">
      <Button
        dataTest="merchant_portal_button"
        kind="secondary"
        onClick={openMerchantPortalUrl}
      >
        <FormattedMessage
          id="developer.success.page.portal.button"
          defaultMessage="Go to Merchant Portal"
        />
      </Button>
    </StyledButtonContainer>
  );
};

export default MerchantPortalButton;
