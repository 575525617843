export const reducerInput = (state, action) => {
  return { ...state, [action.key]: action.value };
};

export const reducerError = (state, action) => {
  switch (action.type) {
    case 'update_error':
      return { ...state, [action.key]: action.value };
    case 'reset_error':
      return { ...state, [action.key]: '' };

    default:
      return state;
  }
};

export const reducerIcon = (state, action) => {
  switch (action.type) {
    case 'success_icon':
      return { ...state, [action.key]: 'success' };
    case 'error_icon':
      return { ...state, [action.key]: 'error' };
    default:
      return state;
  }
};
