import { IconNames } from 'og-merchant-portal-react-library';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import AccountCreationLink from './Components/AccountCreationLink';
import ChooseIntegrationButton from './Components/ChooseIntegrationButton';
import { StyledInlineTitle, StyledParagraph, StyledLowMarginParagraph } from './SuccessPage.style';
import ConfirmationTitle from './Components/ConfirmationTitle';

const CheckMail = ({ emailAddress, pspid }) => {
  return (
    <>
      <ConfirmationTitle icon={IconNames.mail}>
        <FormattedMessage id="developer.success.page.title" defaultMessage="Check your email!" />
      </ConfirmationTitle>

      <StyledLowMarginParagraph data-test="check_mail_styled_first_paragraph">
        <FormattedHTMLMessage
          id="developer.success.page.check.email.warning"
          defaultMessage="Check {emailAddress} for a link to set your password for account <b>{pspid}</b>. This helps us keep your account secure."
          values={{ emailAddress, pspid }}
        />
      </StyledLowMarginParagraph>

      <StyledParagraph data-test="check_mail_styled_second_paragraph">
        <FormattedMessage
          id="developer.success.page.email.spam.warning"
          defaultMessage="If you cannot see the email please check your spam folder."
        />
      </StyledParagraph>

      <StyledInlineTitle data-test="what_is_next_title">
        <FormattedMessage
          id="developer.success.page.next.step.message"
          defaultMessage="What’s next?"
        />
      </StyledInlineTitle>

      <StyledParagraph data-test="what_is_next_paragraph">
        <FormattedMessage
          id="developer.success.page.secure.account.message"
          defaultMessage="After you secure your test account, you can build a test integration. The first step is deciding how to link your website with our platform."
        />
      </StyledParagraph>

      <ChooseIntegrationButton />

      <AccountCreationLink />
    </>
  );
};

CheckMail.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  pspid: PropTypes.string.isRequired
};

export default CheckMail;
