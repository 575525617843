import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 528px;
  background: ${props => props.theme.colors.greyScale.white};
  border-radius: ${props => props.theme.borderRadius.rounded}px;
  filter: drop-shadow(0px 0.1875rem 0.875rem rgba(78, 71, 69, 0.3));
  padding: 4rem;
  margin: 1rem;
  position: relative;
`;

export const StyledHeaderDiv = styled.div`
  font-size: 28px;
  font-weight: ${props => props.theme.font.weights.medium};
  margin-top: 10px;
  margin-bottom: 20px;
  color: ${props => props.theme.colors.greyScale.title};
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0 0.625rem 0;
  color: ${props => props.theme.colors.greyScale.body};
`;

export const StyledSpinnerLoadingContainer = styled.div`
  .spinner {
    transform: scale(0.375);
  }
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledInputErrorText = styled.p`
  margin: 0;
  font-size: ${props => props.theme.font.sizes.sm};
`;

export const StyledCaptchaContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 3.5rem;
`;

export const StyledFailedErrorCaptcha = styled.div`
  padding: 0.625rem 0rem;
  color: ${props => props.theme.colors.error.regular};
  font-size: ${props => props.theme.font.sizes.sm};
`;

export const StyledRefreshIconContainer = styled.div`
  margin-left: 1rem;
  path {
    fill: ${props => props.theme.colors.primary.regular};
  }
  &:hover {
    cursor: pointer;
  }
`;

export const StyledCaptchaInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem 0 0.625rem 0;
  color: ${props => props.theme.colors.greyScale.body};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    width: 100%;
    margin: 1rem 0;
  }
`;

export const StyledLanguageSelector = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
  top: 20px;
`;
