/* eslint react/jsx-filename-extension: 0 */ /* Cannot be a jsx file */
import 'react-app-polyfill/ie9';
import 'core-js/stable';
import 'intl';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { unregister } from './serviceWorker';
import { getAvailableTranslationsList } from './common/helpers/Internationalization/translationApi';
import UserPreferences from './common/helpers/Internationalization/userPreferences';

const availableTranslations = getAvailableTranslationsList();
let { lang: userLang } = UserPreferences.getPreSelectedLocaleOrDefault();
if (availableTranslations.indexOf(userLang) === -1) {
  userLang = 'en';
}

const fetchTheme = fetch('/themes/theme.json', { cache: 'no-cache' });
const fetchTranslation = fetch(`/assets/${userLang}.json`);

Promise.all([fetchTheme, fetchTranslation])
  .then(responses => {
    return Promise.all(responses.map(response => response.json()));
  })
  .then(([theme, messages]) => {
    ReactDOM.render(
      <App
        locale={userLang}
        theme={theme}
        translatedMessages={messages}
        availableTranslations={availableTranslations}
      />,
      document.getElementById('root')
    );
    unregister();
  })
  .catch(error => {
    console.error('The theme or translation could not be loaded. Please retry later.');
    throw new Error(error);
  });
