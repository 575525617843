import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../common/helpers/routes';
import CheckMail from './CheckMail';
import AlreadyRegistered from './AlreadyRegistered';
import { StyledContainerDiv } from './SuccessPage.style';

const SuccessPage = () => {
  const location = useLocation();
  const navigation = useNavigate();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!location.state) {
      navigation(ROUTES.home);
      return undefined;
    }
  }, [location, navigation]);

  const emailAddress = location?.state?.emailAddress;
  const ssoUserCreated = location?.state?.ssoUserCreated;
  const pspid = location?.state?.pspid;

  return (
    <StyledContainerDiv data-test="styled_container_div">
      {ssoUserCreated ? (
        <CheckMail emailAddress={emailAddress} pspid={pspid} />
      ) : (
        <AlreadyRegistered emailAddress={emailAddress} pspid={pspid} />
      )}
    </StyledContainerDiv>
  );
};

export default SuccessPage;
