import { Icon, IconNames } from 'og-merchant-portal-react-library';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { ThemeContext } from 'styled-components';

const StyledInformation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const StyledIcon = styled.div`
  margin-right: 5px;
`;

const SetIcon = ({ type }) => {
  const theme = useContext(ThemeContext);
  switch (type) {
    case 'success':
      return (
        <Icon
          data-test="developer.success"
          name={IconNames.check}
          color={theme.colors.success.regular}
          size="1.2rem"
        />
      );
    case 'error':
      return (
        <Icon
          data-test="developer.error"
          name={IconNames.cancel}
          color={theme.colors.error.regular}
          size="1.2rem"
        />
      );
    default:
      return (
        <Icon
          data-test="developer.success"
          name={IconNames.check}
          color={theme.colors.success.regular}
          size="1.2rem"
        />
      );
  }
};

SetIcon.propTypes = {
  type: PropTypes.string.isRequired
};

const InformationTab = ({ id, defaultMessage, type }) => {
  return (
    <StyledInformation data-test="developer.informationTab" id="developer.informationTab">
      <StyledIcon>
        <SetIcon type={type} />
      </StyledIcon>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </StyledInformation>
  );
};

InformationTab.propTypes = {
  id: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

export default InformationTab;
