export default class UserPreferences {
  static getPreSelectedLocaleOrDefault() {
    const preSelectedLanguage = global.localStorage.getItem('locale');
    const language = global.navigator && global.navigator.language;
    let lang;
    let region;
    if (preSelectedLanguage) {
      lang = preSelectedLanguage;
    } else if (!language) {
      lang = 'en';
    } else {
      [lang, region] = language.split('-');
    }
    return {
      lang,
      region
    };
  }
}
