export const isValidEmailAddress = value => {
  return String(value)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidName = value => {
  return String(value)
    .toLowerCase()
    .match(/^[A-Za-zÄÖÜÏßËÉäöüïëéÇÂÊÎÔÛÀÈÌÒÙçâêîôûàèìòùĄĆŁÓŻŚĘąćłóżśęÚÑÍÉÁúñíéá\- ]+$/);
};

export const isValidCompanyName = value => {
  return String(value)
    .toLowerCase()
    .match(/^[a-zA-Z\d ]+$/);
};

const FIELDS_INFO_RANGE = {
  firstName: { range: 35 },
  lastName: { range: 35 },
  companyName: { range: 35 },
  emailAddress: { range: 150 },
  captchaInput: { range: 6 }
};

export const validateInputField = (field, value) => {
  const errors = {};
  if (!value.trim()) {
    errors[field] = {
      id: 'developer.account.creation.form.validation.required',
      defaultMessage: 'Can not be empty'
    };
  } else if (value.length > FIELDS_INFO_RANGE[field].range) {
    errors[field] = {
      id: 'developer.account.creation.form.validation.length',
      defaultMessage: 'The value is too long'
    };
  } else if ((field === 'firstName' || field === 'lastName') && !isValidName(value)) {
    errors[field] = {
      id: `developer.account.creation.form.validation.${field}`,
      defaultMessage: 'Please enter a valid name'
    };
  } else if ((field === 'companyName') && !isValidCompanyName(value)) {
    errors[field] = {
      id: `developer.account.creation.form.validation.${field}`,
      defaultMessage: 'Please enter a valid name'
    };
  } else if (field === 'emailAddress' && !isValidEmailAddress(value)) {
    errors[field] = {
      id: 'developer.account.creation.form.validation.email',
      defaultMessage: 'Please enter a valid email address'
    };
  }

  return errors;
};

//  constants
export const URLs = {
  accountCreationUrl: '/create-account',
  captchaUrl: '/captcha'
};

export const initalFormState = {
  firstName: '',
  lastName: '',
  companyName: '',
  emailAddress: '',
  captchaInput: ''
};
export const initalFormError = {
  firstName: '',
  lastName: '',
  companyName: '',
  emailAddress: '',
  captchaInput: ''
};
export const initalIcon = {
  firstName: '',
  lastName: '',
  companyName: '',
  emailAddress: '',
  captchaInput: ''
};
