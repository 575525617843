import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../common/components/Spinner/Spinner';
import { StyledFailedErrorCaptcha } from './utils/styles';

function Captcha({ imageData, loading }) {
  if (loading) {
    return <Spinner />;
  }

  if (imageData) {
    return <img src={imageData} alt="Captcha" />;
  }

  return (
    <StyledFailedErrorCaptcha>
      <FormattedMessage
        id="developer.capthca.loading.error"
        defaultMessage="Captcha could not be loaded"
      />
    </StyledFailedErrorCaptcha>
  );
}

Captcha.propTypes = {
  imageData: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

export default Captcha;
