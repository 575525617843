import styled from 'styled-components';

const StyledLoading = styled.div`
  justify-content: space-between;
  align-items: center;
  max-height: 4rem;
  font-size: ${props => props.theme.font.sizes.md};
  white-space: nowrap;
  & > div {
    position: relative;
    width: 55px;
    height: 1.6rem;
    margin-top: -0.4rem;
    .spinner {
      transform: scale(0.2);
      transform-origin: left;
    }
  }
`;

export default StyledLoading;
