import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NotificationContext from 'og-merchant-portal-react-library/lib/NotificationContext/NotificationContext';

const NotificationProvider = React.memo(({ children }) => {
  const [notification, setNotification] = useState(null);
  const [secondsTimeout, setSecondsTimeout] = useState(5);

  const value = {
    notification,
    setSecondsTimeout,
    addNotification: notif => {
      if (notification) {
        setNotification(null);
      }
      setNotification(notif);
    }
  };

  useEffect(() => {
    let showNotificationIdInterval;

    if (notification && secondsTimeout) {
      showNotificationIdInterval = setTimeout(() => setNotification(null), secondsTimeout * 1000);
    }
    return () => {
      if (showNotificationIdInterval) {
        clearTimeout(showNotificationIdInterval);
      }
    };
  }, [notification, secondsTimeout]);

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
});

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NotificationProvider;
