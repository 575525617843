// eslint-disable-next-line object-curly-newline
import {
  Button,
  Icon,
  IconNames,
  SpinnerLoading,
  TextInput
} from 'og-merchant-portal-react-library';
import NotificationContext from 'og-merchant-portal-react-library/lib/NotificationContext/NotificationContext';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import InformationTab from '../../common/components/InformationTab/InformationTab';
import LanguageSelector from '../../common/components/LanguageSelector/LanguageSelector';
import httpClient from '../../common/helpers/httpClient';
import ROUTES from '../../common/helpers/routes';
import Captcha from './Captcha';
import { reducerError, reducerIcon, reducerInput } from './utils/reducers';
import {
  StyledButtonContainer,
  StyledCaptchaContainer,
  StyledCaptchaInputContainer,
  StyledContainer,
  StyledHeaderDiv,
  StyledInputContainer,
  StyledInputErrorText,
  StyledLanguageSelector,
  StyledRefreshIconContainer,
  StyledSpinnerLoadingContainer
} from './utils/styles';
import {
  URLs,
  initalFormError,
  initalFormState,
  initalIcon,
  validateInputField
} from './utils/utils';

const CreateForm = ({ intl }) => {
  const isp = httpClient.getSetting('isp');
  const navigation = useNavigate();
  const [stateInput, dispatchInput] = useReducer(reducerInput, initalFormState);
  const [stateError, dispatchError] = useReducer(reducerError, initalFormError);
  const [stateIcon, dispatchIcon] = useReducer(reducerIcon, initalIcon);
  const { addNotification } = useContext(NotificationContext);
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [imageData, setImageData] = useState('');
  const [captchaKey, setCaptchaKey] = useState('');
  const [captchaText, setCaptchaText] = useState('');
  const [refreshCaptcha, setRefreshCaptcha] = useState(false);
  const [captchaLoading, setCaptchaLoading] = useState(false);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    setCaptchaLoading(true);
    httpClient
      .get(URLs.captchaUrl)
      .then(result => {
        setImageData(result.imageData);
        setCaptchaKey(result.key);
        setCaptchaText(result.captchaText);
        setCaptchaLoading(false);
      })
      .catch(() => {
        setCaptchaLoading(false);
      });
  }, [refreshCaptcha]);

  const onInputChange = e => {
    const { name, value } = e.target;
    dispatchInput({ key: name, value });
    dispatchError({ type: 'reset_error', key: name });
    const errors = validateInputField(name, value);
    const errorKeys = Object.keys(errors);
    if (errorKeys.length) {
      const updatedError = {};
      errorKeys.forEach(key => {
        updatedError[key] = (
          <StyledInputErrorText>
            <FormattedMessage id={errors[key].id} defaultMessage={errors[key].defaultMessage} />
          </StyledInputErrorText>
        );
      });
      dispatchError({ type: 'update_error', key: name, value: updatedError[name] });
      dispatchIcon({ type: 'error_icon', key: name });
    } else {
      dispatchIcon({ type: 'success_icon', key: name });
    }
  };

  const submitHandler = e => {
    e.preventDefault();
    setIsSpinnerLoading(true);
    const { firstName, lastName, companyName, emailAddress, captchaInput } = stateInput;
    const captchaRequest = {
      captchaInput,
      captchaText,
      key: captchaKey
    };
    const accountCreationRequest = {
      accountInfo: {
        isp,
        firstName,
        lastName,
        companyName,
        emailAddress
      },
      captcha: {
        captchaInput,
        captchaText,
        key: captchaKey
      }
    };
    httpClient
      .post(URLs.captchaUrl, captchaRequest)
      .then(() => {
        httpClient
          .post(URLs.accountCreationUrl, accountCreationRequest)
          .then(response => {
            setIsSpinnerLoading(false);

            const {pspid} = response;
            const {ssoUserCreated} = response;

            navigation(ROUTES.success, {
              state: {
                 emailAddress,
                 pspid,
                 ssoUserCreated
                }
            });
          })
          .catch(() => {
            setIsSpinnerLoading(false);
            addNotification({
              text: (
                <InformationTab
                  id="developer.account.creation.error"
                  defaultMessage="Something went wrong. Your account has not been created. Please try again later"
                  type="error"
                />
              ),
              type: 'error'
            });
            setRefreshCaptcha(!refreshCaptcha);
          });
      })
      .catch(() => {
        setIsSpinnerLoading(false);
        addNotification({
          text: (
            <InformationTab
              id="developer.capthca.validation.error"
              defaultMessage="Error validating captcha. Please try again"
              type="error"
            />
          ),
          type: 'error'
        });
        setRefreshCaptcha(!refreshCaptcha);
      });
  };

  useEffect(() => {
    const hasAnyError = Object.values(stateError).some(value => Boolean(value));
    const hasAllValue = Object.values(stateInput).every(value => Boolean(value));

    if (hasAnyError || !hasAllValue) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [stateError, stateInput]);

  if (isSpinnerLoading) {
    return (
      <StyledSpinnerLoadingContainer data-test="styled_spinner_loader_container">
        <SpinnerLoading />
      </StyledSpinnerLoadingContainer>
    );
  }

  return (
    <StyledContainer data-test="styled_container">
      <img width="50%" src="themes/logo-expanded.svg" alt="logo" data-test="logo-testid" />
      <StyledHeaderDiv data-test="styled_header">
        <FormattedMessage
          id="developer.account.creation.subheader"
          defaultMessage="Create your test account"
        />
      </StyledHeaderDiv>
      <StyledInputContainer data-test="styled_input_container_firstName">
        <TextInput
          data-test="text_input_firstName"
          label={intl.formatMessage({
            id: 'developer.account.creation.form.firstName.label',
            defaultMessage: 'First name'
          })}
          name="firstName"
          value={stateInput.firstName}
          onChange={onInputChange}
          onKeyDown={onInputChange}
          errorMessage={stateError.firstName}
          icon={stateIcon.firstName}
          iconAlign="right"
        />
      </StyledInputContainer>
      <StyledInputContainer data-test="styled_input_container_lastName">
        <TextInput
          data-test="text_input_lastName"
          label={intl.formatMessage({
            id: 'developer.account.creation.form.lastName.label',
            defaultMessage: 'Last name'
          })}
          name="lastName"
          value={stateInput.lastName}
          onChange={onInputChange}
          onKeyDown={onInputChange}
          errorMessage={stateError.lastName}
          icon={stateIcon.lastName}
          iconAlign="right"
        />
      </StyledInputContainer>
      <StyledInputContainer data-test="styled_input_container_companyName">
        <TextInput
          data-test="text_input_companyName"
          label={intl.formatMessage({
            id: 'developer.account.creation.form.company.label',
            defaultMessage: 'Company name'
          })}
          name="companyName"
          value={stateInput.companyName}
          onChange={onInputChange}
          onKeyDown={onInputChange}
          errorMessage={stateError.companyName}
          icon={stateIcon.companyName}
          iconAlign="right"
        />
      </StyledInputContainer>
      <StyledInputContainer data-test="styled_input_container_emailAddress">
        <TextInput
          data-test="text_input_emailAddress"
          label={intl.formatMessage({
            id: 'developer.account.creation.form.email.label',
            defaultMessage: 'Email address'
          })}
          name="emailAddress"
          value={stateInput.emailAddress}
          onChange={onInputChange}
          onKeyDown={onInputChange}
          errorMessage={stateError.emailAddress}
          icon={stateIcon.emailAddress}
          iconAlign="right"
        />
      </StyledInputContainer>
      <StyledCaptchaContainer data-test="styled_captcha_container">
        <Captcha imageData={imageData} loading={captchaLoading} />
        <StyledRefreshIconContainer
          data-test="styled_refresh_icon_container"
          onClick={() => setRefreshCaptcha(!refreshCaptcha)}
        >
          <Icon
            data-test="developer.refresh"
            name={IconNames.refresh}
            color={theme.colors.primary.regular}
            size="1.7rem"
          />
        </StyledRefreshIconContainer>
      </StyledCaptchaContainer>
      <StyledCaptchaInputContainer data-test="styled_input_container_captcha">
        <TextInput
          data-test="text_input_captcha"
          placeholder={intl.formatMessage({
            id: 'developer.capthca.placeholder',
            defaultMessage: 'Please type the text above'
          })}
          name="captchaInput"
          value={stateInput.captchaInput}
          onChange={onInputChange}
          onKeyDown={onInputChange}
          errorMessage={stateError.captchaInput}
          icon={stateIcon.captchaInput}
          iconAlign="right"
        />
      </StyledCaptchaInputContainer>
      <StyledButtonContainer data-test="styled_button_container">
        <Button disabled={isButtonDisabled} onClick={submitHandler}>
          <FormattedMessage
            id="developer.account.creation.submit.button"
            defaultMessage="Create an account"
          />
        </Button>
      </StyledButtonContainer>
      <StyledLanguageSelector data-test="styled_language_selector">
        <LanguageSelector />
      </StyledLanguageSelector>
    </StyledContainer>
  );
};
CreateForm.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(CreateForm);
