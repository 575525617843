import React, { useContext } from 'react';
import NotificationContext from 'og-merchant-portal-react-library/lib/NotificationContext/NotificationContext';
import Toast from '../components/Toast/Toast';

const Notification = () => {
  const { notification } = useContext(NotificationContext);

  return notification && <Toast type={notification.type || 'info'}>{notification.text}</Toast>;
};

export default Notification;
