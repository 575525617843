import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';
import NotificationProvider from './common/helpers/NotficationProvider';
import Notification from './common/helpers/notification';
import CreateForm from './og-account-creation-client/CreateForm';
import SuccessPage from './og-account-creation-client/SuccessPage';
import GlobalStyles from './common/components/GlobalStyles/index';
import I18nProvider from './common/helpers/Internationalization/I18nProvider';
import ROUTES from './common/helpers/routes';

const AppDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`;

export default function App({ locale, theme, translatedMessages, availableTranslations }) {
  return (
    <I18nProvider
      locale={locale}
      translatedMessages={translatedMessages}
      availableTranslations={availableTranslations}
    >
      <ThemeProvider theme={theme}>
        <AppDiv>
          <NotificationProvider>
            <Notification />
            <Router>
              <Routes>
                <Route path={ROUTES.home} element={<CreateForm theme={theme} />} />
                <Route path={ROUTES.success} element={<SuccessPage theme={theme} />} />
              </Routes>
            </Router>
          </NotificationProvider>
          <GlobalStyles />
        </AppDiv>
      </ThemeProvider>
    </I18nProvider>
  );
}
App.propTypes = {
  translatedMessages: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  availableTranslations: PropTypes.arrayOf(PropTypes.string).isRequired
};
