import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { StyledInlineTitle, StyledParagraph, StyledLowMarginParagraph } from './SuccessPage.style';
import AccountCreationLink from './Components/AccountCreationLink';
import MerchantPortalButton from './Components/MerchantPortalButton';
import ChooseIntegrationButton from './Components/ChooseIntegrationButton';
import ConfirmationTitle from './Components/ConfirmationTitle';

const AlreadyRegistered = ({ emailAddress, pspid }) => {
  return (
    <>
      <ConfirmationTitle icon="checkOutline" isIconInline>
        <FormattedMessage
          id="developer.success.page.already.registered.title"
          defaultMessage="You’re all set!"
        />
      </ConfirmationTitle>

      <StyledParagraph data-test="already_registred_styled_first_paragraph">
        <FormattedHTMLMessage
          id="developer.success.page.already.registered.paragraph"
          defaultMessage="Your account linked to {emailAddress} has been updated with the provided information and
        connected to your new merchant <b>{pspid}</b>."
          values={{ emailAddress, pspid }}
        />
      </StyledParagraph>

      <StyledInlineTitle data-test="what_is_next_title">
        <FormattedMessage
          id="developer.success.page.next.step.message"
          defaultMessage="What’s next?"
        />
      </StyledInlineTitle>

      <StyledParagraph data-test="what_is_next_paragraph">
        <FormattedMessage
          id="developer.success.page.already.registered.secure.integration"
          defaultMessage="The first step is to secure your test account and decide how to link your website with our platform."
        />
      </StyledParagraph>

      <ChooseIntegrationButton />

      <StyledLowMarginParagraph data-test="merchant_portal_paragraph">
        <FormattedMessage
          id="developer.success.page.portal.message"
          defaultMessage="If you’ve already integrated, you’re all set to move forward."
        />
      </StyledLowMarginParagraph>

      <MerchantPortalButton />

      <AccountCreationLink />
    </>
  );
};

AlreadyRegistered.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  pspid: PropTypes.string.isRequired
};

export default AlreadyRegistered;
